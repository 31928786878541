<template>
  <cp-filter
    ref="cpFilter"
    name="investorsHolders"
    @input="updateFilter"
  >
    <div class="form-row align-items-center">
      <div class="col-md-4 filterBlock">
        <cp-select
          name="country-code"
          :label="$t('holders.label.filter.country')"
          :options="countriesList"
        />
      </div>
      <div class="col-md-3 filterBlock">
        <cp-select
          name="investor-type"
          :label="$t('holders.label.filter.investorType')"
          :options="filterLists.investorType"
        />
      </div>
      <div class="col-md-3 filterBlock">
        <cp-range
          name="from-total-tokens"
          :label="$t('holders.label.filter.tokenOwned')"
        />
      </div>
      <div
        v-if="isBeneficial"
        class="col-md-4 filterBlock"
      >
        <cp-select
          name="wallet-owner-name"
          :label="$t('holders.label.filter.walletOwner')"
          :options="walletOwnersList"
        />
      </div>
      <div
        v-if="isBeneficial && canFilterCustodians"
        class="col-md-3 filterBlock"
      >
        <cp-select
          name="custodianId"
          :label="$t('holders.label.filter.custodian')"
          :options="custodiansList"
        />
      </div>
    </div>
  </cp-filter>
</template>

<script>
import { mapGetters } from 'vuex';

import CpFilter from '~/components/common/cp-filter';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpRange from '~/components/common/standalone-components/inputs/cp-range';
import filterParseForApi from '~/utilities/investorFilterParseForApi';

import { filterLists } from './options';

export default {
  name: 'HoldersFilter',
  components: {
    CpFilter,
    CpSelect,
    CpRange,
  },
  props: {
    isBeneficial: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterLists,
    };
  },
  computed: {
    ...mapGetters('global', ['countries']),
    ...mapGetters('issuersInfo', ['issuerWalletManagers']),
    ...mapGetters('custodians', ['getCustodiansList']),
    ...mapGetters('currentOperator', ['authorizationLevel']),
    countriesList() {
      return [
        { i18nKey: 'common.label.all', value: null },
        ...(this.countries.map(
          country => ({ text: country.name, value: country.countryCode }),
        )),
      ];
    },
    walletOwnersList() {
      return [
        { i18nKey: 'common.label.all', value: null },
        { i18nKey: 'holders.label.investor', value: 'investor' },
        ...this.fillWalletOwners(this.issuerWalletManagers),
      ];
    },
    custodiansList() {
      return [{ i18nKey: 'common.label.all', value: null }, ...this.fillCustodians(this.getCustodiansList)];
    },
    hasCustodians() {
      return this.getCustodiansList.length > 0;
    },
    canFilterCustodians() {
      return ['megaadmin', 'superadmin', 'brokerdealer'].includes(this.authorizationLevel) && this.hasCustodians;
    },
  },
  methods: {
    updateFilter() {
      this.$emit('updateFilter', this.getActiveFilters());
    },
    getActiveFilters() {
      return filterParseForApi(this.$refs.cpFilter.getDefaultValue);
    },
    fillWalletOwners({ custodian, omnibus }) {
      return [
        ...(custodian || []).map(
          item => ({ text: item, value: item }),
        ),
        ...(omnibus || []).map(
          item => ({ text: item, value: item }),
        ),
      ];
    },
    fillCustodians(custodiansList) {
      return (custodiansList || []).map(custodian => ({ text: custodian.name, value: custodian.id }));
    },
  },
};
</script>


<style lang="scss">
  .filterBlock {
    margin: 0 2%;
    min-width: 25%;
  }
</style>
