export default {
  'holder-of-records': {
    tableGetUrl: 'investors/getInvestorsList',
    showFilter: true,
    showTableHeaderActions: true,
    pageHeaderActionsType: 'holders',
  },
  'beneficial-owners': {
    tableGetUrl: 'investors/getInvestorsList',
    showFilter: true,
    showTableHeaderActions: true,
    pageHeaderActionsType: 'holders',
  },
  'custodian-management': {
    tableGetUrl: 'custodians/getCustodians',
    showFilter: false,
    showTableHeaderActions: false,
    pageHeaderActionsType: 'custodian',
  },
};
